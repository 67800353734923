var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"mr-sm-4 header-tablepage mb-3"},[_vm._v("Check rich menu")]),_c('div',{staticClass:"title-tabs"},[_c('b-row',{attrs:{"calss":""}},[_c('b-col',{staticClass:"text-left d-flex",attrs:{"md":"6"}},[_c('div',[_vm._v("Search User's Rich Menu")])])],1)],1),_c('div',{staticClass:"p-3 bg-white"},[_c('div',{staticClass:"input-action"},[_c('InputText',{attrs:{"textFloat":"Telephone","value":_vm.displayValue,"placeholder":"Telephone","type":"text","readonly":""},on:{"inputClick":_vm.handleCustomer}}),_c('div',{staticClass:"input-action-icon"},[_c('b-icon',{attrs:{"stacked":"","icon":"search","scale":"0.5","variant":"grey"},on:{"click":_vm.handleCustomer}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"8","xl":"6"}},[_c('div',{staticClass:"mb-1"},[_vm._v("Preview")]),_c('div',{staticClass:"preview-container"},[_c('div',{staticClass:"wrap-panel"},[_c('div',{staticClass:"layout"},[_c('div',{staticClass:"rich-menu-panel"},[_c('b-collapse',{attrs:{"id":"my-collapse","visible":""}},[(!_vm.isLoading)?_c('div',{staticClass:"rich-menu"},[_c('b-img',{ref:"menu-image",attrs:{"src":_vm.form.imageUri || _vm.form.default_image_layout}}),_c('div',{staticClass:"menu"},_vm._l((_vm.form.lineRichTemplateAction),function(cell){return _c('div',{key:cell.id,class:`menu-box ${
                        _vm.form.highlight == cell.sort ? 'highlight' : ''
                      }`,style:({
                        top: `${cell.top}%`,
                        left: `${cell.left}%`,
                        width: `${cell.widthPercent}%`,
                        height: `${cell.heightPercent}%`,
                      })},[_c('div',{staticClass:"sort-menu"},[_vm._v(" "+_vm._s(_vm.convertText(cell.sort))+" ")])])}),0)],1):_c('OtherLoading')],1),_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle:my-collapse",arg:"my-collapse"}],staticClass:"rich-menu-bottom"},[_c('font-awesome-icon',{attrs:{"icon":"bars"}}),_c('div',{staticClass:"mx-auto"},[_vm._v(" "+_vm._s(_vm.form.chatBarText)+" "),_c('font-awesome-icon',{attrs:{"icon":"caret-down"}})],1)],1)],1)])])])]),_c('ModalCustomerList',{ref:"CustomerModal",on:{"select":_vm.handleSelectedCustomer}}),_c('FooterAction',{attrs:{"routePath":"/setting/rich-menu","hideSubmit":""},on:{"submit":_vm.submit}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }